import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import FWBanner from '../../../components/fullWidthBanner';
import FWTextBanner from '../../../components/fullWidthTextBanner';
import FWCard from '../../../components/fullWidthCard';


const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),

    },
    promoHeader: {
        fontFamily: ['"Helvetica Neue"', 'sans-serif'],
        fontWeight: 500,
        letterSpacing: '-0.5px',
        fontSize: '4rem',
        lineHeight: '6rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            lineHeight: '3rem',
        }
    },
    paragraph: {
        marginBottom: theme.spacing(5),
    },
    ullist: {
        fontSize: '1.3rem',
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    }
}))

export default function OurValues(props) {
    const classes = useStyles()
    const locale = 'en-EU'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='ProParent' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.praragraph}>
                    <FWBanner imgName='proparent-desktop.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWTextBanner title='PROPARENT' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWTextBanner bg title='We are responsible for our pet&#39;s life.' subtitle='Ensuring their well-being, health and happiness is crucial. We believe that the wellbeing, health and happiness of our four-legged friends can be ensured by promoting the right pet parenting. Sharing a home with cats and dogs is undoubtedly a privilege. It is very important that this companionship is beneficial and peaceful for both parties. Owning a pet brings many responsibilities along with unlimited love and happiness.' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Basic Needs' subtitle={<ul className={classes.ullist}><li>Providing their nutritional needs</li>
                        <li>Providing fresh water</li>
                        <li>Providing a comfortable living space </li>
                        <li>Regularly walking and exercising</li>
                    </ul>}
                        image='proparent-temel-ihtiyaclar.jpg' bg />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Health Needs' subtitle={<ul className={classes.ullist}><li>Routine check-ups</li>
                        <li>Vaccinations </li>
                        
                        <li>Nail, teeth and hair care</li>
                    </ul>}
                        image='proparent-sagliksal-ihtiyaclar-1.jpg' position='right' bg />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Training and Socialising' subtitle={<ul className={classes.ullist}><li>Teaching basic commands</li>
                        <li>Potty training</li>
                        <li>Socialising with other animals</li>
                        <li>Socialising with other humans</li>
                    </ul>}
                        image='proparent-egitim-sosyallesme-1.jpg' bg />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Responsibility Towards The Environment' subtitle={<ul className={classes.ullist}><li>Keeping on a leash in public</li>
                        <li>Picking up stool</li>
                        <li>Making sure they cause no harm to others</li>
                    </ul>}
                        image='proparent-cevreye-karsi-sorumluluklar.jpg' position='right' bg />
                </Grid>
            </Grid>
        </Layout>
    )

}


